import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('basic-information.people.details'),
    icon: '/images/info.svg',
    componentName: 'PersonDetails',
  },
  {
    name: i18n.t('basic-information.people.education.field-name'),
    icon: '/images/education-document.svg',
    componentName: 'PersonEducations',
  },
  {
    name: i18n.t('basic-information.people.medical-examination.field-name'),
    icon: '/images/medical.svg',
    componentName: 'PersonMedicalExaminations',
  },
  {
    name: i18n.t('basic-information.people.document'),
    icon: '/images/documents.svg',
    componentName: 'PersonDocuments',
  },
];

