












import Vue from 'vue';
import MultipleSelectFilter from '@/components/Header/Filters/MultipleSelectFilter.vue';
import { mapGetters } from 'vuex';
import WorkField from '@/model/work-field';

export default Vue.extend({
  name: 'PersonWorkfieldFilter',
  components: {
    MultipleSelectFilter,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.workFields === undefined || this.workFields.length === 0) {
      this.loading = true;
      this.$store.dispatch('workFieldStorage/getWorkFields').finally(() => (this.loading = false));
    }
  },
  methods: {
    toggleWorkfield(workField: WorkField) {
      this.$store.commit('filterStorage/personFilterStorage/toggleWorkfield', workField);
      this.$emit('filterChanged');
    },
  },
  computed: {
    ...mapGetters({
      personFilterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
      workFields: 'workFieldStorage/getWorkFields',
    }),
  },
});
