












import Vue from 'vue';
import MultipleSelectFilter from '@/components/Header/Filters/MultipleSelectFilter.vue';
import { mapGetters } from 'vuex';
import Protector from '@/model/protector';

export default Vue.extend({
  name: 'PersonűindividualProtectorFilter',
  components: {
    MultipleSelectFilter,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.protectors === undefined || this.protectors.length === 0) {
      this.loading = true;
      this.$store.dispatch('protectorStorage/getProtectors').finally(() => (this.loading = false));
    }
  },
  methods: {
    toggleProtector(protector: Protector) {
      this.$store.commit('filterStorage/personFilterStorage/toggleProtector', protector);
      this.$emit('filterChanged');
    },
  },
  computed: {
    ...mapGetters({
      personFilterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
      protectors: 'protectorStorage/getProtectors',
    }),
  },
});
