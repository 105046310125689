import { render, staticRenderFns } from "./AddedExamItemCard.vue?vue&type=template&id=063227df&scoped=true&"
import script from "./AddedExamItemCard.vue?vue&type=script&lang=ts&"
export * from "./AddedExamItemCard.vue?vue&type=script&lang=ts&"
import style0 from "./AddedExamItemCard.vue?vue&type=style&index=0&id=063227df&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063227df",
  null
  
)

export default component.exports