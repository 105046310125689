




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import { PersonCategory } from '@/model/person-category';

export default Vue.extend({
  name: 'DeleteCategory',
  components: {
    DeleteButton,
  },
  props: {
    category: { type: Object as () => PersonCategory },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteCategory() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            `${this.$t('basic-information.category.delete', {
              name: this.category.name,
            })} ${
              this.category.items && this.category.items.length
                ? this.$t('basic-information.category.items-to-uncategorized')
                : ''
            }`.toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('personCategoryStorage/delete', this.category)
              .then(() => this.$emit('delete'))
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
