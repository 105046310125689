
















































































































import Vue from 'vue';
import PersonFilter from '@/components/BasicInformation/People/PersonFilter.vue';
import PersonRow from '@/components/BasicInformation/People/PersonRow.vue';
import PersonDetailsForm from '@/components/BasicInformation/People/PersonDetailsForm.vue';
import DeleteCategory from '@/components/BasicInformation/People/Category/DeleteCategory.vue';
import AddButton from '@/components/Button/AddButton.vue';
import AutoIdToggle from '@/components/InputField/Toggle.vue';
import PersonDetailedView from '@/components/BasicInformation/People/PersonDetailedView.vue';
import HeaderBase from '@/components/Header/HeaderBase.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import CategoryForm from '@/components/BasicInformation/CategoryForm.vue';
import CategoryExpansionPanel from '@/components/BasicInformation/CategoryExpansionPanel.vue';
import { mapGetters } from 'vuex';
import Person from '@/model/person';
import AutoIncrementingIdRequest from '@/model/request/auto-incrementing-id-request';
import BasicInformationRequest from '@/model/request/basic-information-request';
import { PersonCategory } from '@/model/person-category';
import TodoTasksForm from '@/components/BasicInformation/People/TodoTasksForm.vue';
import { formatterMixin } from '@/mixins/formatters';

export default Vue.extend({
  name: 'BasicInformationPeople',
  components: {
    PersonFilter,
    AddButton,
    AutoIdToggle,
    CategoryForm,
    CategoryExpansionPanel,
    Checkbox,
    DeleteCategory,
    HeaderBase,
    PersonDetailedView,
    PersonDetailsForm,
    PersonRow,
    TodoTasksForm,
  },
  data() {
    return {
      loading: false,
      headerImage: '/images/basic-info-name.svg',
      selectedPerson: {} as Person,
      selectedPeople: [] as Person[],
    };
  },
  mounted() {
    (this as any).fetch();
    this.$emit('setFilter', this.searchText);
    this.$store.dispatch('configurationStorage/getPersonAutoIncrementingId');
  },
  computed: {
    ...mapGetters({
      people: 'personStorage/getPageableOperationalUnitPeople',
      uncategorizedPeople: 'personStorage/getUncategorizedPeople',
      categories: 'personCategoryStorage/getCategories',
      filtering: 'filterStorage/personFilterStorage/isFiltering',
      searchText: 'filterStorage/getBasicInformationSearchText',
      isAutoIncrementing: 'configurationStorage/isPersonAutoIncrementingId',
      isAdmin: 'currentUserStorage/isAdmin',
    }),
    displayableCategories(): PersonCategory[] {
      return this.searchText.length > 0 || this.filtering
        ? this.categories.filter((category: PersonCategory) => category.items.length > 0)
        : this.categories;
    },
    selectedPersonIds(): number[] {
      return this.selectedPeople.map((e) => e.id);
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('personStorage/getCategorizedPeople', this.$route.params?.id)
        .finally(() => (this.loading = false));
    },
    textFiltering() {
      this.fetch();
    },
    openDetailedView(item: Person) {
      this.selectedPerson = item;
      this.$nextTick(() => {
        (this.$refs.detailedView as any).open();
      });
    },
    closeDetailedView() {
      this.selectedPerson = {} as Person;
    },
    update(modifiedPerson: Person) {
      this.selectedPerson = modifiedPerson;
    },
    changeAutoId(value: boolean) {
      this.$store.dispatch('configurationStorage/setPersonAutoIncrementingId', new AutoIncrementingIdRequest(value));
    },
    addCategory(name: string) {
      this.$store
        .dispatch('personCategoryStorage/add', new BasicInformationRequest(name))
        .then((data) => {
          if (data) {
            (this.$refs.categoryAddition as any).closeDialog();
          }
        })
        .finally(() => {
          (this.$refs.categoryAddition as any).resetLoading();
        });
    },
    modifyCategory(name: string, categoryId: number) {
      const form = (this.$refs[`${categoryId}-expansionPanel`] as any)[0];
      this.$store
        .dispatch('personCategoryStorage/modify', new BasicInformationRequest(name, categoryId))
        .then((data) => {
          if (data) {
            form.closeDialog();
          }
        })
        .finally(() => {
          form.resetDialogLoading();
        });
    },
    onPersonSelected(person: Person) {
      const index = this.selectedPeople.findIndex((e) => e.id === person.id);

      if (index >= 0) {
        this.selectedPeople.splice(index, 1);
      } else {
        this.selectedPeople.push(person);
      }
    },
    onTaskAddClick() {
      (this.$refs.taskAdditionDialog as any).openDialog(
        this.selectedPeople
          .map((person) => formatterMixin.methods.fullName(person))
          .sort()
          .join('\n'),
      );
    },
    onTodoCreated() {
      this.selectedPeople.splice(0);
    },
  },
});
