















































































import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Table from '@/components/Table/Table.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Button from '@/components/Button/Button.vue';
import ExamForm from '@/components/BasicInformation/People/ExamForm.vue';
import EditIcon from '@/../public/images/edit.svg?inline';
import Person from '@/model/person';
import PageOptions from '@/model/page/page-options';
import { EducationRequest } from '@/model/request/education-request';
import { MedicalExaminationRequest } from '@/model/request/medical-examination-request';
import Education from '@/model/education';
import Pageable from '@/model/page/pageable';
import MedicalExamination from '@/model/medical-examination';

export default Vue.extend({
  name: 'ExamPageBase',
  components: {
    SearchBar,
    Table,
    InfiniteScroll,
    LoadingIndicator,
    Button,
    ExamForm,
    EditIcon,
  },
  props: {
    person: { type: Object as () => Person },
    items: { type: Object as () => Pageable<Education | MedicalExamination> },
    addButtonText: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    uploading: { type: Boolean, default: false },
  },
  data() {
    return {
      editableItem: {} as EducationRequest | MedicalExaminationRequest,
      tableFields: [
        {
          text: this.$t('basic-information.people.education.name'),
          key: 'name',
          label: this.$t('basic-information.people.education.name'),
          sortable: true,
          thStyle: 'width: 22%',
        },
        {
          text: this.$t('basic-information.people.education.date'),
          key: 'date',
          label: this.$t('basic-information.people.education.date'),
          sortable: true,
          thStyle: 'width: 19.5%',
        },
        {
          text: this.$t('basic-information.people.education.period'),
          key: 'period',
          label: this.$t('basic-information.people.education.period'),
          thStyle: 'width: 16.5%',
        },
        {
          text: this.$t('basic-information.people.education.valid'),
          key: 'expiryDate',
          label: this.$t('basic-information.people.education.valid'),
          sortable: true,
          thStyle: 'width: 17.5%',
        },
        {
          text: this.$t('basic-information.people.education.attachments'),
          key: 'documents',
          label: this.$t('basic-information.people.education.attachments'),
          thStyle: 'width: 19.5%',
        },
        {
          key: 'deleteButton',
          label: '',
          thStyle: 'width: 5%',
        },
      ],
    };
  },
  methods: {
    textFiltering(searchText: string) {
      this.$emit('textFiltering', searchText);
    },
    onInfiniteLoad(): void {
      this.$emit('infiniteLoad');
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.$emit('pageOptionsChanged', newPageOptions);
    },
    openAdditionForm() {
      (this.$refs.additionForm as any).open();
    },
    openEdit(item: Education | MedicalExamination) {
      this.editableItem = {
        id: item.id,
        name: item.name,
        date: item.date ? item.date.toString() : '',
        period: item.period,
        expiryDate: item?.expiryDate ? item.expiryDate.toString() : '',
        documents: item.documents,
      } as EducationRequest | MedicalExaminationRequest;
      this.$nextTick(() => (this.$refs.modificationForm as any).open());
    },
    saveNew(request: EducationRequest | MedicalExaminationRequest) {
      this.$emit('saveNew', request);
    },
    modify(request: EducationRequest | MedicalExaminationRequest) {
      this.$emit('modify', request);
    },
  },
});
