





































import Vue from 'vue';
import ExamForm from '@/components/BasicInformation/People/ExamForm.vue';
import EditIcon from '@/../public/images/edit.svg?inline';
import { EducationRequest } from '@/model/request/education-request';
import { MedicalExaminationRequest } from '@/model/request/medical-examination-request';

export default Vue.extend({
  name: 'AddedExamItemCard',
  components: {
    ExamForm,
    EditIcon,
  },
  props: {
    item: { type: Object as () => EducationRequest | MedicalExaminationRequest },
  },
  methods: {
    openEdit() {
      (this.$refs.form as any).open();
    },
    modify(request: EducationRequest | MedicalExaminationRequest) {
      this.$emit('modify', request);
    },
  },
});
