












import Vue from 'vue';
import MultipleSelectFilter from '@/components/Header/Filters/MultipleSelectFilter.vue';
import { mapGetters } from 'vuex';
import Qualification from '@/model/qualification';

export default Vue.extend({
  name: 'PersonQualificationFilter',
  components: {
    MultipleSelectFilter,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.qualifications === undefined || this.qualifications.length === 0) {
      this.loading = true;
      this.$store.dispatch('qualificationStorage/getQualifications').finally(() => (this.loading = false));
    }
  },
  methods: {
    toggleQualification(qualification: Qualification) {
      this.$store.commit('filterStorage/personFilterStorage/toggleQualification', qualification);
      this.$emit('filterChanged');
    },
  },
  computed: {
    ...mapGetters({
      personFilterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
      qualifications: 'qualificationStorage/getQualifications',
    }),
  },
});
