var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex row m-0"},[_c('SearchBar',{ref:"textFilter",staticClass:"col-9 m-0 py-0",attrs:{"hasPrependIcon":""},on:{"filter":_vm.textFiltering}}),_c('div',{staticClass:"col-3 p-0 pl-4"},[(_vm.uploading)?_c('LoadingIndicator',{staticClass:"mx-auto my-3"}):_c('ExamForm',{ref:"additionForm",attrs:{"buttonText":_vm.$t('basic-information.add')},on:{"add":_vm.saveNew},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.canDoBasicInformationActions)?_c('Button',{staticClass:"w-100",attrs:{"icon":"fas fa-plus","text":_vm.addButtonText},on:{"click":_vm.openAdditionForm}}):_vm._e()]},proxy:true}])})],1)],1),_c('div',{staticClass:"position-relative mt-4"},[_c('Table',{attrs:{"isInfiniteScroll":"","isDetail":"","isLoading":_vm.loading,"data":_vm.items,"fields":_vm.tableFields,"formattedColumns":['name', 'date', 'period', 'expiryDate', 'documents', 'deleteButton'],"stickyHeader":"27.5rem"},on:{"newPageOption":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.name))])]}},{key:"date",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateShort(rowData.date)))])]}},{key:"period",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(("" + (rowData.period ? _vm.formatPeriod(rowData.period) : '-'))))])]}},{key:"expiryDate",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateShort(rowData.expiryDate)))])]}},{key:"documents",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.documents && rowData.documents.length)?_c('div',_vm._l((rowData.documents),function(document){return _c('div',{key:document.id},[_c('a',{staticClass:"doc-name",attrs:{"href":document.file.path,"download":document.file.originalName}},[_c('span',[_vm._v(_vm._s(_vm.shortenFileName(document.file.originalName, 20)))])])])}),0):_vm._e()]}},{key:"deleteButton",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex row align-center m-0"},[_c('ExamForm',{ref:"modificationForm",attrs:{"item":_vm.editableItem,"buttonText":_vm.$t('basic-information.save')},on:{"add":_vm.modify},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('EditIcon',{staticClass:"edit-icon",on:{"click":function($event){return _vm.openEdit(rowData)}}})]},proxy:true}],null,true)}),(_vm.canDoBasicInformationActions)?[_vm._t("delete",null,{"item":rowData})]:_vm._e()],2)]}}],null,true)}),(!_vm.loading && _vm.items.currentPage < _vm.items.totalPages)?_c('InfiniteScroll',{attrs:{"currentPage":_vm.items.currentPage,"totalPages":_vm.items.totalPages},on:{"infiniteLoad":_vm.onInfiniteLoad}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }