














































import Vue from 'vue';
import UnitTable from '@/components/BasicInformation/UnitTable.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Status from '@/components/Dashboard/Table/TableColumns/Status.vue';
import Person from '@/model/person';

export default Vue.extend({
  name: 'PersonRow',
  components: {
    UnitTable,
    Checkbox,
    Status,
  },
  props: {
    data: { type: Array as () => Person[] },
    selectedItemIds: { type: Array as () => number[] },
    loading: { type: Boolean, default: false },
  },
  methods: {
    showDetails(item: Person) {
      this.$emit('showDetails', item);
    },
    isChecked(item: Person) {
      return this.selectedItemIds.includes(item.id);
    },
    onSelect(person: Person) {
      this.$emit('personSelected', person);
    },
  },
});
