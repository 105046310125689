




































































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import PersonDetails from '@/components/BasicInformation/People/Details.vue';
import PersonEducations from '@/components/BasicInformation/People/Education/Educations.vue';
import PersonMedicalExaminations from '@/components/BasicInformation/People/MedicalExamination/MedicalExamination.vue';
import PersonDocuments from '@/components/BasicInformation/People/Documents.vue';
import DeletePerson from '@/components/BasicInformation/People/DeletePerson.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import TextField from '@/components/InputField/TextField.vue';
import { menus } from '@/constants/menu/person-menus';
import Person from '@/model/person';
import { required, maxLength } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'PersonDetailedView',
  components: {
    DetailedViewBase,
    ActionButton,
    PersonDetails,
    PersonEducations,
    PersonMedicalExaminations,
    PersonDocuments,
    DeletePerson,
    LoadingIndicator,
    Navigation,
    TextField,
  },
  props: {
    person: { type: Object as () => Person },
  },
  data() {
    return {
      menus,
      opened: false,
      editing: false,
      saving: false,
      submitted: false,
      activeComponent: 'PersonDetails',
      name: {
        firstName: this.person?.firstName,
        lastName: this.person?.lastName,
      },
    };
  },
  computed: {
    isDetailsPage(): boolean {
      return this.activeComponent === 'PersonDetails';
    },
    isEducationsPage(): boolean {
      return this.activeComponent === 'PersonEducations';
    },
    isMedicalExaminationsPage(): boolean {
      return this.activeComponent === 'PersonMedicalExaminations';
    },
  },
  validations: {
    name: {
      firstName: {
        required,
        maxLength: maxLength(100),
      },
      lastName: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.resetEdition();
      this.$emit('close');
    },
    edit() {
      (this as any).loadRoles();
      (this as any).loadWorkFields();
      (this as any).loadQualifications();
      (this as any).loadProtectors();
      (this as any).loadPersonCategories();
      (this.$refs.details as any).reset();
      this.editing = true;
    },
    save() {
      this.submitted = true;
      (this.$refs.details as any).save();
    },
    resetEdition(modifiedPerson?: Person) {
      this.editing = false;
      this.name.firstName = modifiedPerson ? modifiedPerson.firstName : this.person.firstName;
      this.name.lastName = modifiedPerson ? modifiedPerson.lastName : this.person.lastName;
      if (this.isDetailsPage) {
        (this.$refs.details as any).reset();
      }
    },
    setSaving(value: boolean) {
      this.saving = value;
      if (!value) {
        this.submitted = false;
      }
    },
    update(modifiedPerson: Person) {
      this.resetEdition(modifiedPerson);
      this.$emit('update', modifiedPerson);
    },
  },
});
