














import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import PersonRole from '@/model/person-role';
import WorkField from '@/model/work-field';
import Qualification from '@/model/qualification';
import Protector from '@/model/protector';

export default Vue.extend({
  name: 'MultipleSelectFilter',
  components: {
    LoadingIndicator,
    Checkbox,
  },
  props: {
    loading: { type: Boolean, default: false },
    options: {
      type: [
        Array as () => PersonRole[],
        Array as () => WorkField[],
        Array as () => Qualification[],
        Array as () => Protector[],
      ],
      default: [],
    },
    selectedArray: { type: Array as () => number[] },
  },
  methods: {
    isChecked(item: PersonRole | WorkField | Qualification | Protector): boolean {
      return this.selectedArray.find((id: number) => id === item.id) !== undefined;
    },
  },
});
