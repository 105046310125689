

















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import PersonStatusFilter from '@/components/BasicInformation/People/PersonFilterComponents/StatusFilter.vue';
import PersonRoleFilter from '@/components/BasicInformation/People/PersonFilterComponents/RoleFilter.vue';
import PersonWorkfieldFilter from '@/components/BasicInformation/People/PersonFilterComponents/WorkfieldFilter.vue';
import PersonQualificationFilter from '@/components/BasicInformation/People/PersonFilterComponents/QualificationFilter.vue';
import PersonIndividualProtectorFilter from '@/components/BasicInformation/People/PersonFilterComponents/IndividualProtectorFilter.vue';
import PersonConditionFilter from '@/components/BasicInformation/People/PersonFilterComponents/ConditionFilter.vue';
import PersonEducationFilter from '@/components/BasicInformation/People/PersonFilterComponents/EducationFilter.vue';
import PersonMedicalExaminationFilter from '@/components/BasicInformation/People/PersonFilterComponents/MedicalExaminationFilter.vue';
import { i18n } from '@/i18n';
import PersonFilterOptions from '@/model/filter/person-filter-options';

interface FilterSubMenu {
  name: string | TranslateResult;
  componentName: string;
}

const filterMenus: FilterSubMenu[] = [
  {
    name: i18n.t('basic-information.people.table-header.status'),
    componentName: 'PersonStatusFilter',
  },
  {
    name: i18n.t('basic-information.people.role'),
    componentName: 'PersonRoleFilter',
  },
  {
    name: i18n.t('basic-information.people.workfield'),
    componentName: 'PersonWorkfieldFilter',
  },
  {
    name: i18n.t('basic-information.people.qualification'),
    componentName: 'PersonQualificationFilter',
  },
  {
    name: i18n.t('basic-information.people.individual-protector'),
    componentName: 'PersonIndividualProtectorFilter',
  },
  {
    name: i18n.t('basic-information.people.condition'),
    componentName: 'PersonConditionFilter',
  },
  {
    name: i18n.t('basic-information.people.education.field-name'),
    componentName: 'PersonEducationFilter',
  },
  {
    name: i18n.t('basic-information.people.medical-examination.field-name'),
    componentName: 'PersonMedicalExaminationFilter',
  },
];

export default Vue.extend({
  props: {
    unitId: {
      type: Number,
    },
  },
  name: 'PersonFilter',
  components: {
    PersonStatusFilter,
    PersonRoleFilter,
    PersonWorkfieldFilter,
    PersonQualificationFilter,
    PersonIndividualProtectorFilter,
    PersonConditionFilter,
    PersonEducationFilter,
    PersonMedicalExaminationFilter,
  },
  data() {
    return {
      mainActive: false,
      filter: '/images/filter.svg',
      activeComponent: {
        name: '',
        componentName: '',
      },
      filterMenus,
    };
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
      isFiltering: 'filterStorage/personFilterStorage/isFiltering',
    }),
  },
  methods: {
    openMain() {
      this.mainActive = true;
    },
    closeMain() {
      this.mainActive = false;
    },
    openSubmenu(newComponent: { name: string; componentName: string }) {
      this.activeComponent = newComponent;
      this.mainActive = false;
    },
    closeSubmenu() {
      this.mainActive = true;
      this.activeComponent = { name: '', componentName: '' };
    },
    resetFilter() {
      this.$store.commit('filterStorage/personFilterStorage/clearPersonFilterOptions');
      this.loadFiltered();
    },
    isModified(componentName: string): boolean {
      let isFilterSet = false;
      const filterOptions: PersonFilterOptions = this.filterOptions;

      switch (componentName) {
        case 'PersonStatusFilter':
          isFilterSet = filterOptions.state !== undefined;
          break;
        case 'PersonRoleFilter':
          isFilterSet = filterOptions.roles && filterOptions.roles.length > 0;
          break;
        case 'PersonWorkfieldFilter':
          isFilterSet = filterOptions.workfields && filterOptions.workfields.length > 0;
          break;
        case 'PersonQualificationFilter':
          isFilterSet = filterOptions.qualifications && filterOptions.qualifications.length > 0;
          break;
        case 'PersonIndividualProtectorFilter':
          isFilterSet = filterOptions.individualProtectors && filterOptions.individualProtectors.length > 0;
          break;
        case 'PersonConditionFilter':
          isFilterSet = filterOptions.condition !== undefined;
          break;
        case 'PersonEducationFilter':
          isFilterSet = filterOptions.educationText.length > 0;
          break;
        case 'PersonMedicalExaminationFilter':
          isFilterSet = filterOptions.medicalExaminationText.length > 0;
          break;
      }
      return isFilterSet;
    },
    selectedItems(componentName: string): number {
      let numberOfSelected = 0;
      const filterOptions: PersonFilterOptions = this.filterOptions;

      switch (componentName) {
        case 'PersonRoleFilter':
          numberOfSelected = filterOptions.roles?.length;
          break;
        case 'PersonWorkfieldFilter':
          numberOfSelected = filterOptions.workfields.length;
          break;
        case 'PersonQualificationFilter':
          numberOfSelected = filterOptions.qualifications.length;
          break;
        case 'PersonIndividualProtectorFilter':
          numberOfSelected = filterOptions.individualProtectors.length;
          break;
      }
      return numberOfSelected;
    },
    loadFiltered() {
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('personStorage/getCategorizedPeople', this.$route.params?.id)
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});
