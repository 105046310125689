



















































































































































































































import Vue from 'vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Select from '@/components/InputField/Select.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Person from '@/model/person';
import { PersonRequest } from '@/model/request/person-request';
import { required, maxLength } from 'vuelidate/lib/validators';
import { notEmptyList } from '@/validators/common';
import WorkField from '@/model/work-field';
import Qualification from '@/model/qualification';
import PersonRole from '@/model/person-role';
import Protector from '@/model/protector';
import { mapGetters } from 'vuex';
import PersonFilterRequest from '@/model/request/filter/person-filter-request';

export default Vue.extend({
  name: 'PersonDetails',
  components: {
    FormField,
    TextField,
    Textarea,
    DatePicker,
    AutoComplete,
    Select,
    Checkbox,
  },
  props: {
    person: { type: Object as () => Person },
    editing: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    submitted: { type: Boolean, default: false },
    editedFirstName: { type: String, default: '' },
    editedLastName: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      form: {
        firstName: this.editedFirstName,
        lastName: this.editedLastName,
        innerId: this.person.innerId,
        comment: this.person.comment,
        workFieldIds: [...this.person.workFields.map((workField: WorkField) => workField.id)],
        qualificationIds: [...this.person.qualifications.map((qualification: Qualification) => qualification.id)],
        roleIds: [...this.person.roles.map((role: PersonRole) => role.id)],
        organizationUnit: this.person.organizationUnit,
        locationCode: this.person.locationCode,
        active: this.person.active,
        category: this.person.category,
        individualProtectorIds: this.person.individualProtectors
          ? [...this.person.individualProtectors.map((protector: Protector) => protector.id)]
          : [],
      },
    };
  },
  watch: {
    editedFirstName() {
      this.form.firstName = this.editedFirstName;
    },
    editedLastName() {
      this.form.lastName = this.editedLastName;
    },
  },
  computed: {
    ...mapGetters({
      roles: 'personRoleStorage/getRoles',
      protectors: 'protectorStorage/getProtectors',
      workFields: 'workFieldStorage/getWorkFields',
      qualifications: 'qualificationStorage/getQualifications',
      categories: 'personCategoryStorage/getPersonCategoryOptions',
      searchText: 'filterStorage/getBasicInformationSearchText',
      filterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
    }),
  },
  validations: {
    form: {
      firstName: {
        required,
        maxLength: maxLength(100),
      },
      lastName: {
        required,
        maxLength: maxLength(100),
      },
      innerId: {
        required,
        maxLength: maxLength(200),
      },
      comment: {
        maxLength: maxLength(5000),
      },
      workFieldIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.workFieldIds);
        },
      },
      qualificationIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.qualificationIds);
        },
      },
      roleIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.roleIds);
        },
      },
      organizationUnit: {
        maxLength: maxLength(2000),
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
    },
  },
  methods: {
    reset() {
      this.form.firstName = this.person.firstName;
      this.form.lastName = this.person.lastName;
      this.form.innerId = this.person.innerId;
      this.form.comment = this.person.comment;
      this.form.workFieldIds = [...this.person.workFields.map((workField: WorkField) => workField.id)];
      this.form.qualificationIds = [
        ...this.person.qualifications.map((qualification: Qualification) => qualification.id),
      ];
      this.form.roleIds = [...this.person.roles.map((role: PersonRole) => role.id)];
      this.form.organizationUnit = this.person.organizationUnit;
      this.form.locationCode = this.person.locationCode;
      this.form.active = this.person.active;
      this.form.category = this.person.category;
      this.form.individualProtectorIds = this.person.individualProtectors
        ? [...this.person.individualProtectors.map((protector: Protector) => protector.id)]
        : [];
      this.$emit('setSaving', false);
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('setSaving', true);

      const filterRequest = new PersonFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }

      this.$store
        .dispatch('personStorage/modify', {
          request: {
            id: this.person?.id,
            filter: filterRequest.requestParamsToArray(),
            ...this.form,
          } as PersonRequest,
          prevCategory: this.person.category,
        })
        .then((data) => {
          if (data) {
            this.$emit('update', data);
          }
        })
        .finally(() => {
          this.$emit('setSaving', false);
        });
    },
  },
});
