
























































import Vue from 'vue';
import moment, { Duration, DurationInputArg2, DurationInputObject } from 'moment';
import AddButton from '@/components/Button/CircleButton.vue';
import SaveButton from '@/components/Button/Button.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import PeriodSelect from '@/components/InputField/PeriodSelect.vue';
import FileUpload from '@/components/InputField/FileUpload.vue';
import { FILTER_DATE_FORMAT } from '@/constants/date-format';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import PeriodicOption from '@/model/periodic-option';
import { EducationRequest } from '@/model/request/education-request';
import FileRequest from '@/model/request/file-request';
import Document from '@/model/document';
import { MedicalExaminationRequest } from '@/model/request/medical-examination-request';

export default Vue.extend({
  name: 'ExamForm',
  components: {
    AddButton,
    SaveButton,
    FormField,
    TextField,
    DatePicker,
    PeriodSelect,
    FileUpload,
  },
  props: {
    item: { type: Object as () => EducationRequest | MedicalExaminationRequest },
    loading: { type: Boolean, default: false },
    buttonText: { type: String, default: '' },
    fieldText: { type: String, default: '' },
  },
  data() {
    return {
      opened: false,
      submitted: false,
      form: {
        name: '',
        date: '',
        period: {} as PeriodicOption,
        expiryDate: '',
        files: new Array<File>(),
        documents: [] as Document[],
      },
    };
  },
  watch: {
    ['form.date']() {
      this.updateValidity();
    },
    ['form.period']() {
      this.updateValidity();
    },
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      date: {
        required,
      },
      period: {
        required: requiredIf(function(this: any) {
          return !this.form.expiryDate && !this.form.period?.term;
        }),
      },
      expiryDate: {
        required: requiredIf(function(this: any) {
          return !this.form.expiryDate && !this.form.period?.term;
        }),
      },
    },
  },
  computed: {
    formattedDate: {
      get(): string {
        return (this as any).formatDate(this.form.date);
      },
      set(value: string) {
        this.form.date = value;
      },
    },
    formattedExpiryDate: {
      get(): string {
        return (this as any).formatDate(this.form.expiryDate);
      },
      set(value: string) {
        this.form.expiryDate = value;
      },
    },
    periodOrExpiryDateError(): string {
      return this.submitted && (!this.$v.form.period?.required || !this.$v.form.expiryDate?.required)
        ? this.$t('basic-information.people.education.expiry-date-or-period-required').toString()
        : '';
    },
  },
  methods: {
    setFormFields() {
      this.form.name = this.item.name;
      this.form.date = this.item.date ? this.item.date.toString() : '';
      this.form.period = this.item.period;
      this.form.expiryDate = this.item.expiryDate ? this.item.expiryDate.toString() : '';
      this.form.files = this.item?.files ? [...this.item?.files] : [];
      this.form.documents = this.item?.documents ? [...this.item.documents] : [];
    },
    resetForm() {
      this.form.name = '';
      this.form.date = '';
      this.form.period = {} as PeriodicOption;
      this.form.expiryDate = '';
      this.form.files = [] as File[];
      (this.$refs.datePicker as any)?.resetDate();
      (this.$refs.expiryDatePicker as any)?.resetDate();
    },
    open() {
      if (this.item) {
        this.setFormFields();
      }
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.submitted = false;
      this.resetForm();
    },
    save() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('add', {
        id: this.item?.id,
        ...this.form,
        fileRequests: this.form.files.map((file: File) => new FileRequest(file.name, file.size)),
      } as EducationRequest | MedicalExaminationRequest);
      this.$nextTick(() => this.close());
    },
    updateValidity() {
      const { frequencyNumber, term } = this.form.period || {};

      if (this.form.date && frequencyNumber && term) {
        this.form.expiryDate = moment(this.form.date)
          .add(frequencyNumber, (term as unknown) as DurationInputArg2)
          .format(FILTER_DATE_FORMAT);
      }
    },
  },
});
