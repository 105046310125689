










































































































































































































































































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import FormBase from '@/components/Form/Form.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import TextField from '@/components/InputField/TextField.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import FileUpload from '@/components/InputField/FileUpload.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Select from '@/components/InputField/Select.vue';
import AddedExamItemCard from '@/components/BasicInformation/People/AddedExamItemCard.vue';
import AddButton from '@/components/Button/CircleButton.vue';
import ExamForm from '@/components/BasicInformation/People/ExamForm.vue';
import { mapGetters } from 'vuex';
import { PersonRequest } from '@/model/request/person-request';
import { notEmptyList } from '@/validators/common';
import { EducationRequest } from '@/model/request/education-request';
import { MedicalExaminationRequest } from '@/model/request/medical-examination-request';
import { PersonCategory } from '@/model/person-category';
import PersonFilterRequest from '@/model/request/filter/person-filter-request';

export default Vue.extend({
  name: 'PersonDetailsForm',
  components: {
    FormBase,
    FormField,
    Textarea,
    TextField,
    Checkbox,
    FileUpload,
    DatePicker,
    AutoComplete,
    Select,
    AddedExamItemCard,
    AddButton,
    ExamForm,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        innerId: '',
        comment: '',
        workFieldIds: [] as number[],
        qualificationIds: [] as number[],
        roleIds: [] as number[],
        organizationUnit: '',
        locationCode: '',
        active: false,
        category: null as PersonCategory | null,
        individualProtectorIds: [] as number[],
        educationRequests: [] as EducationRequest[],
        medicalExaminationRequests: [] as MedicalExaminationRequest[],
      },
      attachments: new Array<File>(),
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        maxLength: maxLength(100),
      },
      lastName: {
        required,
        maxLength: maxLength(100),
      },
      innerId: {
        required,
        maxLength: maxLength(200),
      },
      comment: {
        maxLength: maxLength(5000),
      },
      workFieldIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.workFieldIds);
        },
      },
      qualificationIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.qualificationIds);
        },
      },
      roleIds: {
        notEmptyList(this: any) {
          return notEmptyList(this.form.roleIds);
        },
      },
      organizationUnit: {
        maxLength: maxLength(2000),
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
    },
  },
  computed: {
    ...mapGetters({
      searchText: 'filterStorage/getBasicInformationSearchText',
      roles: 'personRoleStorage/getRoles',
      protectors: 'protectorStorage/getProtectors',
      workFields: 'workFieldStorage/getWorkFields',
      qualifications: 'qualificationStorage/getQualifications',
      categories: 'personCategoryStorage/getPersonCategoryOptions',
      isAutoIncrementing: 'configurationStorage/isPersonAutoIncrementingId',
      filterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions',
    }),
  },
  methods: {
    resetForm() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.innerId = '';
      this.form.comment = '';
      this.form.workFieldIds = [] as number[];
      this.form.qualificationIds = [] as number[];
      this.form.roleIds = [] as number[];
      this.form.organizationUnit = '';
      this.form.locationCode = '';
      this.form.active = false;
      this.form.individualProtectorIds = [] as number[];
      this.form.category = null as PersonCategory | null;
      this.form.educationRequests = [] as EducationRequest[];
      this.form.medicalExaminationRequests = [] as MedicalExaminationRequest[];
      this.attachments = [] as File[];
      this.submitted = false;
    },
    openDialog() {
      (this as any).loadRoles();
      (this as any).loadWorkFields();
      (this as any).loadQualifications();
      (this as any).loadProtectors();
      (this as any).loadPersonCategories();
      if (this.isAutoIncrementing) {
        this.$store.dispatch('personStorage/getNextId').then((data: string) => (this.form.innerId = data));
      }
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    openEducationForm() {
      (this.$refs.educationForm as any).open();
    },
    onEducationAdition(education: EducationRequest) {
      this.form.educationRequests.push(education);
    },
    onEducationRemoval(index: number) {
      this.form.educationRequests.splice(index, 1);
    },
    onEducationModification(index: number, education: EducationRequest) {
      Object.assign(this.form.educationRequests[index], education);
    },
    openMedicalExaminationForm() {
      (this.$refs.medicalExaminationForm as any).open();
    },
    onMedicalExaminationAdition(medicalExamination: MedicalExaminationRequest) {
      this.form.medicalExaminationRequests.push(medicalExamination);
    },
    onMedicalExaminationRemoval(index: number) {
      this.form.medicalExaminationRequests.splice(index, 1);
    },
    onMedicalExaminationModification(index: number, medicalExamination: MedicalExaminationRequest) {
      Object.assign(this.form.medicalExaminationRequests[index], medicalExamination);
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const formData = new FormData();
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });
      this.form.educationRequests.forEach((request: EducationRequest) => {
        request?.files?.forEach((file: File) => {
          formData.append('educationFile', file);
        });
      });
      this.form.medicalExaminationRequests.forEach((request: MedicalExaminationRequest) => {
        request?.files?.forEach((file: File) => {
          formData.append('medicalExaminationFile', file);
        });
      });
      formData.append(
        'person',
        new Blob(
          [
            JSON.stringify({
              ...this.form,
              operationalUnitId: parseInt(this.$route.params?.id, 10),
            } as PersonRequest),
          ],
          {
            type: 'application/json',
          },
        ),
      );
      const filterRequest = new PersonFilterRequest(this.searchText, this.filterOptions);
      if (this.$route.params.id) {
        filterRequest.operationalUnitId = +this.$route.params.id;
      }
      formData.append(
        'filter',
        new Blob([JSON.stringify(filterRequest.requestParamsToArray())], {
          type: 'application/json',
        }),
      );
      this.loading = true;
      this.$store
        .dispatch('personStorage/save', formData)
        .then((data) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
