



















import Vue from 'vue';
import ExamPageBase from '@/components/BasicInformation/People/ExamPageBase.vue';
import DeleteEducation from '@/components/BasicInformation/People/Education/DeleteEducation.vue';
import Person from '@/model/person';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import { EducationRequest } from '@/model/request/education-request';

export default Vue.extend({
  name: 'PersonEducations',
  components: {
    ExamPageBase,
    DeleteEducation,
  },
  props: {
    person: { type: Object as () => Person },
  },
  mounted() {
    this.fetch(this.pageOptions, this.filterOptions);
  },
  data() {
    return {
      loading: false,
      uploading: false,
      modifying: false,
      pageOptions: new PageOptions(0, 20),
      filterOptions: new OperationalUnitFilterRequest(''),
    };
  },
  computed: {
    ...mapGetters({ educations: 'educationStorage/getPersonEducations' }),
  },
  methods: {
    fetch(pageOptions: PageOptions, filterOptions: OperationalUnitFilterRequest) {
      this.loading = true;
      this.$store
        .dispatch('educationStorage/getPersonEducations', {
          id: this.person?.id,
          pageOptions,
          filterOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.filterOptions.text = searchText;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onInfiniteLoad(): void {
      this.pageOptions.page++;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.pageOptions = newPageOptions;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onDelete(deletedId: number) {
      this.$store
        .dispatch('educationStorage/deletePersonEducation', { person: this.person, educationId: deletedId })
        .finally(() => {
          if (this.$refs.educations) {
            (this.$refs.educations as any).resetLoading();
          }
        });
    },
    saveNew(education: EducationRequest) {
      this.uploading = true;

      const formData = new FormData();
      education?.files?.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'education',
        new Blob([JSON.stringify(education)], {
          type: 'application/json',
        }),
      );
      this.$store
        .dispatch('educationStorage/addPersonEducation', { person: this.person, formData })
        .finally(() => (this.uploading = false));
    },
    modify(education: EducationRequest) {
      this.modifying = true;
      const formData = new FormData();
      education?.files?.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'education',
        new Blob([JSON.stringify(education)], {
          type: 'application/json',
        }),
      );
      this.$store
        .dispatch('educationStorage/modify', { person: this.person, id: education?.id, formData })
        .finally(() => (this.modifying = false));
    },
  },
});
