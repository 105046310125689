





import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Notification from '@/model/notification';
import MedicalExamination from '@/model/medical-examination';

export default Vue.extend({
  name: 'DeleteMedicalExamination',
  components: {
    LoadingIndicator,
  },
  props: {
    medicalExamination: { type: Object as () => MedicalExamination },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteMedicalExamination() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.people.medical-examination.delete', {
              title: this.medicalExamination.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$emit('delete');
          }
        });
    },
    resetLoading() {
      this.loading = false;
    },
  },
});
