









import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import { mapGetters } from 'vuex';
import PersonFilterOptions from '@/model/filter/person-filter-options';

export default Vue.extend({
  name: 'PersonEducationFilter',
  components: {
    SearchBar,
  },
  data() {
    return {
      text: '',
    };
  },
  mounted() {
    this.text = (this.filterOptions as PersonFilterOptions).educationText;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/personFilterStorage/getPersonFilterOptions' }),
  },
  methods: {
    setText(value: string) {
      this.text = value;
      this.$store.commit('filterStorage/personFilterStorage/setEducationText', value);
      this.$emit('filterChanged');
    },
  },
});
