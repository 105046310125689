





import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Notification from '@/model/notification';
import Education from '@/model/education';

export default Vue.extend({
  name: 'DeleteEducation',
  components: {
    LoadingIndicator,
  },
  props: {
    education: { type: Object as () => Education },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteEducation() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.people.education.delete', {
              title: this.education.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$emit('delete');
          }
        });
    },
    resetLoading() {
      this.loading = false;
    },
  },
});
