
























import Vue from 'vue';
import ExamPageBase from '@/components/BasicInformation/People/ExamPageBase.vue';
import DeleteMedicalExamination from '@/components/BasicInformation/People/MedicalExamination/DeleteMedicalExamination.vue';
import Person from '@/model/person';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import { MedicalExaminationRequest } from '@/model/request/medical-examination-request';

export default Vue.extend({
  name: 'PersonMedicalExaminations',
  components: {
    ExamPageBase,
    DeleteMedicalExamination,
  },
  props: {
    person: { type: Object as () => Person },
  },
  mounted() {
    this.fetch(this.pageOptions, this.filterOptions);
  },
  data() {
    return {
      loading: false,
      uploading: false,
      pageOptions: new PageOptions(0, 20),
      filterOptions: new OperationalUnitFilterRequest(''),
    };
  },
  computed: {
    ...mapGetters({ medicalExaminations: 'medicalExaminationStorage/getPersonMedicalExaminations' }),
  },
  methods: {
    fetch(pageOptions: PageOptions, filterOptions: OperationalUnitFilterRequest) {
      this.loading = true;
      this.$store
        .dispatch('medicalExaminationStorage/getPersonMedicalExaminations', {
          id: this.person?.id,
          pageOptions,
          filterOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.filterOptions.text = searchText;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onInfiniteLoad(): void {
      this.pageOptions.page++;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.pageOptions = newPageOptions;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onDelete(deletedId: number) {
      this.$store
        .dispatch('medicalExaminationStorage/deletePersonMedicalExamination', {
          person: this.person,
          medicalExaminationId: deletedId,
        })
        .finally(() => {
          if (this.$refs.delete) {
            (this.$refs.delete as any).resetLoading();
          }
        });
    },
    saveNew(medicalExamination: MedicalExaminationRequest) {
      this.uploading = true;

      const formData = new FormData();
      medicalExamination?.files?.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'medicalExamination',
        new Blob([JSON.stringify(medicalExamination)], {
          type: 'application/json',
        }),
      );
      this.$store
        .dispatch('medicalExaminationStorage/addPersonMedicalExamination', { person: this.person, formData })
        .finally(() => (this.uploading = false));
    },
    modify(medicalExamination: MedicalExaminationRequest) {
      const formData = new FormData();
      medicalExamination?.files?.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'medicalExamination',
        new Blob([JSON.stringify(medicalExamination)], {
          type: 'application/json',
        }),
      );
      this.$store.dispatch('medicalExaminationStorage/modify', {
        person: this.person,
        id: medicalExamination?.id,
        formData,
      });
    },
  },
});
